/* .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.modal-dialog {
    max-height: 90vh;
    overflow-y: auto;
}

.custom-form {
    max-height: 80vh;
    overflow-y: auto;
    padding: 10px;
} */



.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

.modal.is-active {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-background {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.modal-content {
    background-color: #fff;
    padding: 0px;
    border-radius: 5px;
    width: 60%;
    overflow: hidden;


}

.modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background: transparent;
    cursor: pointer;
}


.box {
    padding: 30px;
    margin: 0 auto;
}

.field {
    margin-bottom: 20px;
}

.label {
    font-weight: bold;
}

.input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button:hover {
    background-color: #0056b3;
}

.custom-otp {
    letter-spacing: 38px;
    text-align: center;
    padding: 0.375rem 1.75rem
}

.backArrowLogin {
    font-size: 32px;
    cursor: pointer;
}

.otp-container {
    display: flex;
}

.otp-input {
    width: 40px;
    height: 40px;
    margin: 0 5px;
    font-size: 20px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.otp-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.opt input {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .3)
}

.opt div {
    justify-content: center !important;
    text-align: center !important;
}

.close-icon {
    font-size: 25px;
    color: #fff;
}

@media (min-width: 320px) {
    .modal-content {

        overflow-y: auto;
    }

    /* .nonImg {
        display: none;
    } */
}

@media (min-width: 481px) {
    .modal-content {
        max-height: 90vh;
        overflow-y: auto;
    }

    /* .nonImg {
        display: none;
    } */
}

@media (min-width: 641px) {
    .modal-content {
        max-height: 90vh;
        overflow-y: auto;
    }

    /* .nonImg {
        display: none;
    } */
}

@media (min-width: 768px) {
    .modal-content {
        width: 90%;
        /* Mobile-specific styles here */
        max-height: 90vh;
        overflow-y: auto;
    }
}

/* Mobile styles (up to 768px) */
@media only screen and (max-width: 768px) {


    .close-icon {
        font-size: 25px;
        color: #000;
    }
}

/* Tablet styles (769px - 1024px) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {}

/* Landscape tablet styles (769px - 1024px) */
@media only screen and (min-width: 769px) and (max-width: 1024px) and (orientation: landscape) {
    .your-class {
        /* Landscape tablet-specific styles here */
    }
}

/* Portrait tablet styles (769px - 1024px) */
@media only screen and (min-width: 769px) and (max-width: 1024px) and (orientation: portrait) {
    .your-class {
        /* Portrait tablet-specific styles here */
    }
}