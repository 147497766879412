.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    overflow: visible;
    /* Allow dropdown to be visible */
}

.modal.is-active {
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-icon-checkput {
    color: rgb(126 87 194);
    font-size: 26px;

}

.modal-background {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.modal-content {
    background-color: #fff;
    padding: 0px;
    border-radius: 5px;
    width: 60%;
}

.modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background: transparent;
    cursor: pointer;
}


.box {
    padding: 30px;
    margin: 0 auto;
}

.field {
    margin-bottom: 20px;
}

.label {
    font-weight: bold;
}

.input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button:hover {
    background-color: #0056b3;
}

.custom-otp {
    letter-spacing: 38px;
    text-align: center;
    padding: 0.375rem 1.75rem
}

.backArrowLogin {
    font-size: 32px;
    cursor: pointer;
}

.otp-container {
    display: flex;
}

.otp-input {
    width: 40px;
    height: 40px;
    margin: 0 5px;
    font-size: 20px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.otp-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.opt input {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .3)
}

.opt div {
    justify-content: center !important;
    text-align: center !important;
}

.close-icon {
    font-size: 25px;
    color: #fff;
}

/* Mobile styles (up to 768px) */
@media only screen and (max-width: 768px) {
    .modal-content {
        width: 90%;
        /* Mobile-specific styles here */
        overflow: auto;
    }

    .checkput-content {
        margin-top: 230px;
    }

    .close-icon {
        font-size: 25px;
        color: #000;
    }
}

/* Tablet styles (769px - 1024px) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .modal-content {
        /* Tablet-specific styles here */
        overflow: auto;
    }
}

/* Landscape tablet styles (769px - 1024px) */
@media only screen and (min-width: 769px) and (max-width: 1024px) and (orientation: landscape) {
    .modal-content {
        /* Tablet-specific styles here */
        overflow: auto;
    }
}

/* Portrait tablet styles (769px - 1024px) */
@media only screen and (min-width: 769px) and (max-width: 1024px) and (orientation: portrait) {
    .modal-content {
        /* Tablet-specific styles here */
        overflow: auto;
    }
}

@media (max-width: 768px) {
    .opt input {
        width: 3rem !important;
        height: 3rem;
        margin: 0 0.5rem !important;
        font-size: 2rem;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, .3);
    }

    .modal-content {
        width: 90%;
        margin: auto;
        padding: 15px;
        overflow: auto;
    }

    /* .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
    } */

    .img-fluid {
        max-width: 100%;
        height: auto;
    }
}

.modal-background {
    background-color: rgba(0, 0, 0, 0.7);
}

.checkout .autocomplete-dropdown {
    position: absolute;
    background-color: #fff;
    z-index: 10000;
    top: 100%;
    /* Position below the input */
    left: 0;
    right: 0;
    max-height: 140px;
    overflow-y: auto;
    /* Changed from overlay to auto */
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    border-radius: 9px;
}



.error-message {
    color: red;
    font-size: 12px;
}