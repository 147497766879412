/* SalesPhoneList.css */
.sales-phone-list {
    width: 100%;
}

/* AccordionItem.css */
.accordion-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.accordion-header {
    background-color: #f0f0f0;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.accordion-content {
    padding: 10px;
}

.arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
}

.open {
    transform: rotate(180deg);
}

.badge-danger {
    background-color: #ffd1d0;
    color: red;
}

.font24 {
    font-size: 24px;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* STYLES */
/* --------------------------------

Primary style

-------------------------------- */


*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}



img {
    max-width: 100%;
}



/* --------------------------------

Modules - reusable parts of our design

-------------------------------- */
.status-container {
    /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
}

.status-container::after {
    /* clearfix */
    content: '';
    display: table;
    clear: both;
}

/* --------------------------------

Main components

-------------------------------- */
header {
    height: 200px;
    line-height: 200px;
    text-align: center;
    background: #303e49;
}

header h1 {
    color: white;
    font-size: 18px;
    font-size: 1.125rem;
}

@media only screen and (min-width: 1170px) {
    header {
        height: 300px;
        line-height: 300px;
    }

    header h1 {
        font-size: 24px;
        font-size: 1.5rem;
    }
}

#status-timeline {
    position: relative;
    padding: 2em 0;
    margin-top: 2em;
    margin-bottom: 2em;
}

#status-timeline::before {
    /* this is the vertical line */
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #d7e4ed;
}

@media only screen and (min-width: 1170px) {


    #status-timeline::before {
        left: 50%;
        margin-left: -2px;
    }
}

.status-timeline-block {
    position: relative;
    margin: 2em 0;
}

.status-timeline-block:after {
    content: "";
    display: table;
    clear: both;
}

.status-timeline-block:first-child {
    margin-top: 0;
}

.status-timeline-block:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
    .status-timeline-block {
        margin: 1em 0;
    }

    .status-timeline-block:first-child {
        margin-top: 0;
    }

    .status-timeline-block:last-child {
        margin-bottom: 0;
    }
}

.status-timeline-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.status-timeline-img img {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
}

.status-timeline-img.status-picture {
    background: #75ce66;
}

.status-timeline-img.status-movie {
    background: #c03b44;
}

.status-timeline-img.status-location {
    background: #f0ca45;
}

@media only screen and (min-width: 1170px) {
    .status-timeline-img {
        width: 37px;
        height: 37px;
        left: 50%;
        margin-left: -19px;
        -webkit-transform: translateZ(0);
        -webkit-backface-visibility: hidden;
    }

    .cssanimations .status-timeline-img.is-hidden {
        visibility: hidden;
    }

    .cssanimations .status-timeline-img.bounce-in {
        visibility: visible;
        -webkit-animation: status-bounce-1 0.6s;
        -moz-animation: status-bounce-1 0.6s;
        animation: status-bounce-1 0.6s;
    }
}

@-webkit-keyframes status-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
    }
}

@-moz-keyframes status-bounce-1 {
    0% {
        opacity: 0;
        -moz-transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -moz-transform: scale(1.2);
    }

    100% {
        -moz-transform: scale(1);
    }
}

@keyframes status-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

.status-timeline-content {
    position: relative;
    margin-left: 60px;
    background: white;
    padding: 1em;
    border: 1px solid #cccc;
    border-radius: 10px;
}

.status-timeline-content:after {
    content: "";
    display: table;
    clear: both;
}

.status-timeline-content h2 {
    color: #303e49;
}

.status-timeline-content p,
.status-timeline-content ul,
.status-timeline-content .status-read-more,
.status-timeline-content .status-date {
    font-size: 13px;
    font-size: 0.8125rem;
}

.status-timeline-content .status-read-more,
.status-timeline-content .status-date {
    display: inline-block;
}

.status-timeline-content p {
    margin: 0em 0;
    line-height: 1.6;
}

.status-timeline-content ul {
    margin: 1em 0;
    line-height: 1.6;
}

.status-timeline-content .status-read-more {
    float: right;
    padding: .8em 1em;
    background: #acb7c0;
    color: white;
    border-radius: 0.25em;
}

.no-touch .status-timeline-content .status-read-more:hover {
    background-color: #bac4cb;
}

.status-timeline-content .status-date {
    float: left;
    padding: .8em 0;
    opacity: .7;
}



@media only screen and (min-width: 768px) {
    .status-timeline-content h2 {
        font-size: 20px;
        font-size: 1.25rem;
    }

    .status-timeline-content p {
        font-size: 16px;
        font-size: 1rem;
    }

    .status-timeline-content .status-read-more,
    .status-timeline-content .status-date {
        font-size: 14px;
        font-size: 0.875rem;
    }
}

@media only screen and (min-width: 1170px) {
    .status-timeline-content {
        margin-left: 0;
        padding: 1.6em;
        width: 45%;
    }


    .status-timeline-content .status-read-more {
        float: left;
    }

    .status-timeline-content .status-date {
        position: absolute;
        width: 100%;
        left: 122%;
        top: 6px;
        font-size: 16px;
        font-size: 1rem;
    }

    .status-timeline-block:nth-child(even) .status-timeline-content {
        float: right;
    }

    .status-timeline-block:nth-child(even) .status-timeline-content::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent;
        border-right-color: white;
    }

    .status-timeline-block:nth-child(even) .status-timeline-content .status-read-more {
        float: right;
    }

    .status-timeline-block:nth-child(even) .status-timeline-content .status-date {
        left: auto;
        right: 122%;
        text-align: right;
    }

    .cssanimations .status-timeline-content.is-hidden {
        visibility: hidden;
    }

    .cssanimations .status-timeline-content.bounce-in {
        visibility: visible;
        -webkit-animation: status-bounce-2 0.6s;
        -moz-animation: status-bounce-2 0.6s;
        animation: status-bounce-2 0.6s;
    }
}

@media only screen and (min-width: 1170px) {

    /* inverse bounce effect on even content blocks */
    .cssanimations .status-timeline-block:nth-child(even) .status-timeline-content.bounce-in {
        -webkit-animation: status-bounce-2-inverse 0.6s;
        -moz-animation: status-bounce-2-inverse 0.6s;
        animation: status-bounce-2-inverse 0.6s;
    }
}

@-webkit-keyframes status-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes status-bounce-2 {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -moz-transform: translateX(20px);
    }

    100% {
        -moz-transform: translateX(0);
    }
}

@keyframes status-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes status-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes status-bounce-2-inverse {
    0% {
        opacity: 0;
        -moz-transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -moz-transform: translateX(-20px);
    }

    100% {
        -moz-transform: translateX(0);
    }
}

@keyframes status-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

.border-l {
    border-left: 1px solid #ccc;
}

.mr-5 {
    margin-right: 6px;
}

.download-btn {
    background-color: #000;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}