.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal.is-active {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-background {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}



.modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background: transparent;
    cursor: pointer;
}


.box {
    padding: 30px;
    margin: 0 auto;
}

.field {
    margin-bottom: 20px;
}

.label {
    font-weight: bold;
}

.input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button:hover {
    background-color: #0056b3;
}

.custom-otp {
    letter-spacing: 38px;
    text-align: center;
    padding: 0.375rem 1.75rem
}

.backArrowLogin {
    font-size: 32px;
    cursor: pointer;
}

.otp-container {
    display: flex;
}

.otp-input {
    width: 40px;
    height: 40px;
    margin: 0 5px;
    font-size: 20px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.otp-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.opt input {
    width: 3rem !important;
    height: 3rem;
    margin: 0 0.4rem;
    font-size: inherit;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .3)
}

.opt div {
    justify-content: center !important;
    text-align: center !important;
}

.close-icon {
    font-size: 25px;
    color: #fff;
}


/* Mobile styles (up to 768px) */
@media only screen and (max-width: 768px) {
    .sell-now-Model {
        width: 90%;
    }

    .close-icon {
        font-size: 25px;
        color: #000;
    }
}

/* Tablet styles (769px - 1024px) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .sell-now-Model {
        width: 40% !important;
    }
}

/* Landscape tablet styles (769px - 1024px) */
@media only screen and (min-width: 769px) and (max-width: 1024px) and (orientation: landscape) {
    .sell-now-Model {
        width: 40% !important;
    }
}

/* Portrait tablet styles (769px - 1024px) */
@media only screen and (min-width: 769px) and (max-width: 1024px) and (orientation: portrait) {
    .sell-now-Model {
        width: 40% !important;
    }

}




.autocomplete {
    position: relative;
    display: inline-block;
}

.autocomplete input {}

.autocomplete ul {
    position: absolute !important;

    background-color: #fff;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: overlay;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    border-radius: 9px;
}

.autocomplete ul li {
    padding: 8px;
    cursor: pointer;
    font-size: 14px;
}

.autocomplete ul li:hover {
    background-color: #f9f9f9;
    cursor: pointer;
}


.delete-btn {
    background-color: rgb(254 100 97) !important;
    border: 2px solid transparent;
    border-radius: var(--border-radius-large);
    color: var(--white-color) !important;
    font-family: var(--title-font-family);
    font-size: var(--p-font-size);
    font-weight: var(--font-weight-semibold);
    line-height: normal;
    transition: all 0.3s;
    padding: 10px 20px;
}

.cancel-btn {
    background-color: #fff !important;
    border: 1px solid #7e57c2;
    border-radius: var(--border-radius-large);
    color: #7e57c2 !important;
    font-family: var(--title-font-family);
    font-size: var(--p-font-size);
    font-weight: var(--font-weight-semibold);
    line-height: normal;
    transition: all 0.3s;
    padding: 10px 20px;
}

.cancel-btn:hover {
    background-color: #7e57c2 !important;
    color: #fff !important;
}