/* sell-mobile.css */

.sell-mobile-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.sell-mobile-title {
  font-size: 24px;
  color: #333;
}

.sell-mobile-list {
  list-style-type: none;
}

.sell-mobile-list-item {
  margin-bottom: 10px;
}
