/* Style for the modal overlay */
.modal-overlayM {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow: auto;
    /* Ensure scrolling is allowed on the overlay */
}

/* Style for the modal content */
.modal-contentM {
    background: #fff;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
    overflow-y: auto;
    /* Allow vertical scrolling if content overflows */
    max-height: 90vh;
    /* Limit the height to 90% of viewport height */
}

/* Style for the close button */
.close-btn {
    background: transparent;
    border: none;
    font-size: 24px;
    color: #333;
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
}

.close-btn:hover {
    color: #e74c3c;
}

/* Style for the heading */
.modal-contentM h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Style for the city list */
.city-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

/* Style for each city item */
.city-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;
    /* height: 150px; */
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
    cursor: pointer;
    background-color: #fff;
}

/* Hover effect for city item */
.city-item:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px #f0f8ffbf;
    background-color: #f0f8ffbf;
}

/* Ensure the logo inside the city item scales correctly */
.city-logo {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-bottom: 10px;
}

/* Style for the city name */
.city-item p {
    margin: 0;
    font-size: 0.9rem;
    color: #555;
}