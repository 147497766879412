/* Base Styles for desktop */
.carousel img {
    max-height: 500px;
    /* Default height for desktop */
    object-fit: cover;
    /* Ensure image covers the entire container */
    width: 100%;
}

/* Desktop version (show only on screens larger than 992px) */
.desktop_sliderDiv {
    display: block;
}

.mobile_sliderDiv {
    display: none;
}

/* Mobile version (show only on screens smaller than 992px) */
@media (max-width: 991px) {
    .desktop_sliderDiv {
        display: none;
    }

    .mobile_sliderDiv {
        display: block;
    }
}




/* Responsive Styles for mobile view */
@media (max-width: 768px) {
    .carousel img {
        max-height: 450px;
        /* Increased height for mobile view */
        object-fit: cover;
        /* height: 103px; */
        /* Keep the image aspect ratio */
    }

    .carousel-indicators {
        /* margin-bottom: 0 */
    }
}