.brand-image {
  border-radius: var(--border-radius-medium);
  display: block;
  margin: 8px auto;
  height: 120px;
  padding: 10px 10px;
}

.itemfont {
  font-size: 0.8rem;
}

.check-group {
  background: #fff;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 10, 0.2);
  counter-reset: total;
  counter-reset: checked;
}

.check-group>*+* {
  margin-top: 0.75rem;
}

.check-group .checkbox {
  counter-increment: total;
}

.check-group input[type=checkbox]:checked {
  counter-increment: checked;
}

.check-group__result {
  font-weight: bold;
  padding-top: 0.75rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.check-group__result:after {
  content: counter(checked) " / " counter(total);
  padding-left: 1ch;
}

.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.checkbox__input {
  position: absolute;
  width: 1.375em;
  height: 1.375em;
  opacity: 0;
  cursor: pointer;
}

.checkbox__input:checked+.checkbox__icon .tick {
  stroke-dashoffset: 0;
}

.checkbox__icon {
  width: 1.375em;
  height: 1.375em;
  flex-shrink: 0;
  overflow: visible;
}

.checkbox__icon .tick {
  stroke-dasharray: 20px;
  stroke-dashoffset: 20px;
  transition: stroke-dashoffset 0.2s ease-out;
}

.checkbox__label {
  margin-left: 0.5em;
}

.border-lg {
  border: 1px solid #ccc;
}

.check-group__title {
  font-weight: bold;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.team-thumb {
  height: auto !important;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.card {
  display: flex;
  flex-direction: column;
  flex-basis: 300px;
  flex-shrink: 0;
  flex-grow: 0;
  max-width: 100%;
  background-color: #FFF;
  border-radius: var(--border-radius-medium);
  overflow: hidden;
  margin-bottom: 1rem;
}

.card-img {
  padding-bottom: 56.25%;
  position: relative;
}

.card-img img {
  position: absolute;
  width: 100%;
}

.card-body {
  padding: 1.5rem;
}

.card-title {
  font-size: 1.25rem;
  line-height: 1.33;
  font-weight: 700;
}

.card-title.skeleton {
  min-height: 28px;
  border-radius: 4px;
}

.card-intro {
  margin-top: 0.75rem;
  line-height: 1.5;
}

.card-intro.skeleton {
  min-height: 72px;
  border-radius: 4px;
}

.skeleton {
  background-color: #e2e5e7;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  -webkit-animation: shine 1s ease infinite;
  animation: shine 1s ease infinite;

}

.skeleton-phone {
  height: 181px;
  width: 40%;
  padding: 10px 10px;
  margin: 8px auto;
}

@-webkit-keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}