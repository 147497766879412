/* Mobile Menu Styles */
.navbar-collapse.show {
    display: block;
}


@media (max-width: 768px) {
    .m-ScreenImg-icon {
        max-width: 80%;
        height: auto;
    }

    .navbar-collapse {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        z-index: 9999;
    }

    .navbar-collapse.show {}

    .navbar-nav {
        flex-direction: column;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .navbar-nav .nav-link {
        padding: 15px 0;
    }

    .close-menu {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 24px;
        border: none;
        background: transparent;
        cursor: pointer;
        color: black;
    }

    .dropdown-menu.mobile-dropdown-menu {
        position: absolute;
        top: 20px;
        /* Adjust based on your header height */
        right: 0;
        width: 100%;
        z-index: 999;
        background: white;
        border: 1px solid #ddd;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
}

.navbar-toggler {
    width: 60px;
    text-align: center;
    z-index: 99999;
    background-color: #fff;
}


/* Mobile Search Bar Styling */
.mobile-search-bar {
    width: 100%;
    padding: 10px;
    background-color: white;
    position: relative;
    margin-top: 0%;

    /* Adjust depending on your navbar height */
    left: 0;
    z-index: 9999;
    border-bottom: 1px solid #ddd;
}

.mobile-search-bar .input-group {
    width: 100%;
}

@media (min-width: 768px) {
    .mobile-search-bar {
        display: block;
        /* Hide search bar on larger screens */
    }
}


.mobile_device_serchIcon {
    background: none;
    border: none;
    font-size: 24px;
    padding: 0;
    margin: 0;
    color: #333;
}

.mobile_device_serchIcon:hover {
    color: #007bff;
}

.navbar-nav1 {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.nav-item {
    margin: 0 8px;
}

/* Icon Sizes for Larger Screens */
@media (min-width: 768px) {
    /* .navbar-collapse {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        z-index: 9999;
        height: 50vh;
        margin-top: 60px;
    } */

    .nav-link i {
        font-size: 28px;
    }

    .nav-link {
        font-size: 14px;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }
}


/* Mobile Menu Styles */
.navbar-collapse.show {
    display: block;
}

@media (max-width: 768px) {
    .m-ScreenImg-icon {
        max-width: 80%;
        height: auto;
    }

    .navbar-collapse {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        z-index: 9999;
        height: auto;
        margin-top: 60px;
    }

    .navbar-collapse.show {}

    .navbar-nav {
        flex-direction: column;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: start;
    }

    .navbar-nav .nav-link {
        padding: 15px 0;
    }

    .dropdown-menu.mobile-dropdown-menu {
        position: absolute;
        top: 30px;
        /* Adjust based on your header height */
        right: 0;
        width: 100%;
        background: white;
        border: 1px solid #ddd;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
}