/* SalesPhoneList.css */
.sales-phone-list {
    width: 100%;
}

/* AccordionItem.css */
.accordion-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.accordion-header {
    background-color: #f0f0f0;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.accordion-content {
    padding: 10px;
}

.arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
}

.open {
    transform: rotate(180deg);
}

@media (min-width:320px) {

    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .sales-order-serchInput {
        max-width: 100% !important;
    }

    .text-center-sm {
        text-align: center;
    }
}

@media (min-width:480px) {
    .text-center-sm {
        text-align: center;
    }

    /* smartphones, Android phones, landscape iPhone */
}

@media (min-width:600px) {

    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width:801px) {

    /* tablet, landscape iPad, lo-res laptops ands desktops */
    .text-center-sm {
        text-align: start;
    }
}

@media (min-width:1025px) {

    /* big landscape tablets, laptops, and desktops */
    .text-center-sm {
        text-align: start;
    }
}

@media (min-width:1281px) {
    .text-center-sm {
        text-align: start;
    }

    /* hi-res laptops and desktops */
}