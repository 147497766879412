.features {
  border-top: 1px solid #cccc;
  padding: 12px 2px;
  margin-top: 14px;
}


.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

}

.checkbox-group .checkbox {
  width: calc(50% - 1rem);
  /* Adjust this for the spacing between boxes */
  margin: 0.5rem;
}

.checkbox-group>* {
  margin: 0.5rem 0.5rem;
}

.checkbox-group-legend {
  font-size: 1.5rem;
  font-weight: 700;
  color: #9c9c9c;
  text-align: center;
  line-height: 1.125;
  margin-bottom: 1.25rem;
}

.checkbox-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox-input:checked+.checkbox-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #2260ff;
}

.checkbox-input:checked+.checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #2260ff;
  border-color: #2260ff;
}

.checkbox-input:checked+.checkbox-tile .checkbox-icon,
.checkbox-input:checked+.checkbox-tile .checkbox-label {
  color: #2260ff;
}

.checkbox-input:focus+.checkbox-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
}

.checkbox-input:focus+.checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
}

.checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 13rem;
  min-height: 7rem;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}


.checkbox-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpath d='M128,32,16,224H240Zm-8,136a12,12,0,1,1,12-12A12,12,0,0,1,120,168Zm8-48a12,12,0,0,1,12,12v40a12,12,0,0,1-24,0V132A12,12,0,0,1,128,120Z' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/path%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.checkbox-tile:hover {
  border-color: #2260ff;
}

.checkbox-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
}

.checkbox-icon svg {
  width: 3rem;
  height: 3rem;
}

.checkbox-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
}



.custom-block1 {
  border: none;
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
  padding: 30px;
  transition: all 0.3s ease;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
}

.features-info ul {
  padding-left: 2rem;
}


.features-info ul li::before {
  content: '\2022';
  /* Unicode for bullet (disc) */
  color: var(--secondary-color);
  /* Change color to red */
  margin-right: 0.5em;
  /* Adjust spacing if needed */
  font-size: 16px;
  font-weight: bold;
}

.custom-block-info {
  display: block;
  padding: 0px !important;
  padding-bottom: 0;
}



.form-step:not(:first-child) {
  display: block;
}

.form-step.show {
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.backbtn {
  background-color: #000 !important;
  background: #000 !important;
}

input:focus {
  outline: none;
}

.checkboxScreen {
  border: 1px solid #ccc;
  box-shadow: none;
}

.checkbox-input:checked+.checkboxScreen {
  border-color: #000;
  box-shadow: 0 12px 10px rgba(0, 0, 0, 0.1);
  color: #000;
}

.checkboxScreen:hover {
  border-color: #000;
}

.checkbox-input:checked+.checkboxScreen:before {
  transform: scale(1);
  opacity: 1;
  background-color: #7e57c2;
  border-color: #7942dc;
}

.checkboxScreen:before {
  content: "";
  position: absolute;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}



.FunctionalError {
  border: 1px solid #ccc;
  box-shadow: none;
}

.checkbox-input:checked+.FunctionalError:before {
  transform: scale(1);
  opacity: 1;
  background-color: rgb(254 100 97);
  ;
  border-color: rgb(254 100 97);
  ;
}

.checkbox-input:checked+.FunctionalError {
  border-color: rgb(254 100 97);
  box-shadow: 0 12px 10px rgba(0, 0, 0, 0.1);
  color: #000;
}

.FunctionalError:hover {

  border-color: rgb(254 100 97);
}

input[type="checkbox"]:focus {
  outline: none;
  background-color: unset;
}

.checkbox-input:focus+.FunctionalError {
  border-color: #ccc;
  box-shadow: none
}

.checkbox-input:checked+.FunctionalError {
  border-color: rgb(254 100 97);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: rgb(254 100 97);
}

.checkbox-input:checked+.FunctionalError:before {
  transform: scale(1);
  opacity: 1;
  background-color: rgb(254 100 97);
  border-color: rgb(254 100 97);
}

.checkbox-tile {
  width: 100% !important;

}

.option-itemName1 {
  text-align: center;
  color: #000;
  font-weight: 600;
  font-size: 15px !important;
  margin-bottom: 0;
  margin-top: 8px;
  background: var(--section-bg-color);
  width: 12.9rem;
  max-width: 100% !important;
  padding: 14px;
  border-radius: 0 0 0.5rem 0.5rem;
}

.checkbox-input:checked+.checkboxScreen .option-itemName1 {
  background: #7e57c2;
  color: #fff;
}

.checkbox-input:checked+.FunctionalError .option-itemName1 {
  background: rgb(254 100 97);
  color: #fff;
}

@media (min-width:320px) {

  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .container-fluid-custom {
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 30px;

  }

  .option-itemName1 {
    width: 9.9rem;
  }

  .custom-block {
    padding: 10px;
  }

}

@media (min-width:480px) {
  .custom-block {
    padding: 10px;
  }

  /* smartphones, Android phones, landscape iPhone */
  .container-fluid-custom {
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 30px;

  }
}

@media (min-width:600px) {

  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .container-fluid-custom {
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 30px;

  }
}

@media (min-width:801px) {

  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .container-fluid-custom {
    max-width: 100%;
    padding-right: 50px;
    padding-left: 50px;

  }
}

@media (min-width:1025px) {

  /* big landscape tablets, laptops, and desktops */
  .container-fluid-custom {
    max-width: 100%;
    padding-right: 50px;
    padding-left: 50px;

  }
}

@media (min-width:1281px) {

  /* hi-res laptops and desktops */
  .container-fluid-custom {
    max-width: 100%;
    padding-right: 50px;
    padding-left: 50px;

  }
}

@media (min-width: 768px) {
  .checkbox-group .checkbox {
    width: calc(33.33% - 1rem);
    /* 3 boxes per row on tablets and larger */
  }

  .option-itemName1 {
    width: 12.9rem !important;
  }
}

@media (min-width: 1024px) {
  .checkbox-group .checkbox {
    width: calc(25% - 1rem);
    /* 4 boxes per row on desktops and larger */
  }

  .custom-block {
    padding: 30px;
  }

  .option-itemName1 {
    width: 9.9rem !important;
  }

  .custom-block {
    padding: 30px;
  }
}

@media (min-width: 1200px) {
  .checkbox-group .checkbox {
    width: calc(16% - 1rem);
    /* 4 boxes per row on desktops and larger */
  }
}


.checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
}

.checkbox-icon img {
  width: 100%;
  height: auto;
}

.option-itemName1 {
  margin-top: 10px;
  height: 50px;
  /* Adjust the height to ensure uniformity */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  /* Ensure a readable font size */
  padding: 0 5px;
  line-height: 1.2;
  /* Adjust line height for longer text */
  overflow-wrap: break-word;
  /* Handle text wrapping for long words */
  width: 100% !important;
}