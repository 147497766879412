.symIcons {
    width: 50px;
}



.iconBoxSum {
    border: 1px solid #7e57c2 !important;
    padding: 10px;
    background-color: var(--section-bg-color);
    border-radius: var(--border-radius-medium);
    width: 33.33%;
    margin: 10px;

}

.totalAmountSumry {
    border-top: 1px solid #cccc;
    margin-top: 10px;
    padding-top: 6px;
}

.text_content h2 {
    color: hsl(223, 47%, 23%);
    padding: 30px 0 15px 0;
    font-size: 26px;
    font-weight: 900;
}

.text_content p {
    line-height: 1.5;
    color: rgba(153, 153, 153, 0.979);
    font-size: 15px;
}

.plan {

    /* background-color: hsl(225, 100%, 98%); */
    border-radius: 15px;
    padding: 0 10px;
    justify-content: space-between;
}

.orderPrice {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.plan h6 {
    font-size: larger;
    font-weight: 900;
    color: hsl(223, 47%, 23%);
}



.PTP {
    display: block;
    background-color: hsl(261.87deg 46.72% 55.1%);
    font-size: 14px;
    padding: 14px;
    margin: 30px;
    border-radius: var(--border-radius-large) !important;
    text-decoration: none;
    color: white;
    font-size: medium;
    font-weight: 500;
    box-shadow: none !important;
    text-align: center;
    margin-bottom: 0;
    width: 100%
}

.CO {
    text-decoration: none;
    color: rgba(153, 153, 153, 0.979);
    font-size: 13px;
    font-weight: 800;
}

.payment-options {
    margin-bottom: 0px;
}

@media only screen and (max-width: 425px) {
    /* * {
        max-width: 100vw;
        max-height: 100vh;
    } */




    .text_content h2 {
        font-size: 23px;
    }

    .text_content p {
        font-size: 13px;
    }

    .plan {
        padding: 12px;
    }

}

/* Accordion.css */

/* Accordion container */
.accordion {
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

/* Accordion header */
.accordion-header {
    background-color: var(--section-bg-color);
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

/* Arrow icon */
.arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
}

.open {
    transform: rotate(180deg);
}

/* Accordion content */
.accordion-content {
    padding: 10px;
}




/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    /* CSS rules for extra small devices */
    .Edflex {
        display: block;
        justify-content: space-between;
    }

    .text-alignment {
        text-align: center;
    }

    .iconBoxSum {
        width: 90%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {

    /* CSS rules for small devices */
    .Edflex {
        display: block;
        justify-content: space-between;
    }

    .iconBoxSum {
        width: 100%;
    }

    .text-alignment {
        text-align: center;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {

    /* CSS rules for medium devices */
    .Edflex {
        display: flex;
        justify-content: space-between;
    }

    .text-alignment {
        text-align: left;
    }
}

/* Large devices (laptops/desktops, 1025px and up) */
@media only screen and (min-width: 1025px) and (max-width: 1440px) {

    /* CSS rules for large devices */
    .Edflex {
        display: flex;
        justify-content: space-between;
    }

    .text-alignment {
        text-align: left;
    }
}


/* Extra large devices (large laptops and desktops, 1441px and up) */
@media only screen and (min-width: 1441px) {

    /* CSS rules for extra large devices */
    .Edflex {
        display: flex;
        justify-content: space-between;
    }
}

/* Portrait orientation */
@media only screen and (orientation: portrait) {
    /* CSS rules for portrait orientation */
}

/* Landscape orientation */
@media only screen and (orientation: landscape) {
    /* CSS rules for landscape orientation */
}

.custom-btn1 {
    background-color: var(--custom-btn-bg-color) !important;
    border: 2px solid transparent;
    border-radius: var(--border-radius-large);
    color: var(--white-color) !important;
    font-family: var(--title-font-family);
    font-size: var(--p-font-size) !important;
    font-weight: var(--font-weight-semibold);
    line-height: normal;
    transition: all 0.3s;
    padding: 10px 20px;
}

.recalculate {
    background-color: #fff !important;
    color: #7e57c2 !important;
    border: 1px solid #7e57c2;
}

.recalculate:hover {
    background-color: #7e57c2 !important;
    color: #fff !important;
}