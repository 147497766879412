td {
  width: 50% !important;
  text-align: right;
}
th {
  width: 50% !important;
}
@media (max-width: 500px) {
  td {
    width: 40% !important;
  }
  th {
    width: 60% !important;
  }
}
