/*---------------------------------------
  CUSTOM PROPERTIES ( VARIABLES )             
-----------------------------------------*/
:root {
  --white-color: #ffffff;
  --primary-color: #4d4d4d;
  --secondary-color: #7e57c2;
  --section-bg-color: #f0f8ff;
  --custom-btn-bg-color: #7e57c2;
  --custom-btn-bg-hover-color: #00cc99;
  --dark-color: #000000;
  --p-color: #717275;
  --border-color: #7fffd4;
  --link-hover-color: #7e57c2;

  --body-font-family: "Montserrat", sans-serif;
  --title-font-family: "Sono", sans-serif;

  --h1-font-size: 28px;
  --h2-font-size: 26px;
  --h3-font-size: 24px;
  --h4-font-size: 22px;
  --h5-font-size: 20px;
  --h6-font-size: 18px;
  --p-font-size: 16px;
  --menu-font-size: 14px;

  --border-radius-large: 100px;
  --border-radius-medium: 20px;
  --border-radius-small: 10px;

  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
}

body {
  background-color: var(--white-color);
  font-family: var(--body-font-family);
  letter-spacing: 0.7px;
}

button {
  letter-spacing: 0.7px;
}

.colorBlack {
  color: #000000;
}

/*---------------------------------------
  TYPOGRAPHY               
-----------------------------------------*/


h2,
h3,
h4,
h5,
h6 {
  color: var(--dark-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title-font-family);
  font-weight: var(--font-weight-semibold);
}

h1 {
  font-size: var(--h1-font-size);
  font-weight: var(--font-weight-bold);
}

h2 {
  font-size: var(--h2-font-size);
  font-weight: var(--font-weight-bold);
}

h3 {
  font-size: var(--h3-font-size);
}

h4 {
  font-size: var(--h4-font-size);
}

h5 {
  font-size: var(--h5-font-size);
}

h6 {
  font-size: var(--h6-font-size);
}

p {
  color: var(--p-color);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-light);
  font-family: var(--title-font-family);
}

ul li {
  font-family: var(--title-font-family);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-light);
}

a,
button {
  touch-action: manipulation;
  transition: all 0.3s;
}

a {
  display: inline-block;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
}

a:hover {
  color: var(--link-hover-color);
}

b,
strong {
  font-weight: var(--font-weight-bold);
}

.secondary-color {
  color: #7e57c2;
}

::selection {
  background-color: var(--primary-color);
  color: var(--white-color);
}

/*---------------------------------------
  SECTION               
-----------------------------------------*/
.section-title-wrap {
  position: relative;
}

.section-title-wrap::after {
  content: "";
  background: var(--section-bg-color);
  width: 100%;
  height: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.section-title {
  background: var(--section-bg-color);
  border-radius: var(--border-radius-large);
  display: inline-block;
  position: relative;
  z-index: 2;
  margin-bottom: 0;
  padding: 10px 25px;
}

.section-padding {
  padding-top: 60px;
  padding-bottom: 60px;
}

main {
  position: relative;
  z-index: 1;
}

.section-bg {
  background-color: var(--section-bg-color);
}

.section-overlay {
  background-color: var(--primary-color);
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  opacity: 0.85;
}

.section-overlay+.container {
  position: relative;
}

/*---------------------------------------
  CUSTOM ICON COLOR               
-----------------------------------------*/
.custom-icon {
  color: var(--primary-color);
}

/*---------------------------------------
  CUSTOM BUTTON               
-----------------------------------------*/
.custom-btn {
  background-color: var(--custom-btn-bg-color);
  border: 2px solid transparent;
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-family: var(--title-font-family);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-semibold);
  line-height: normal;
  transition: all 0.3s;
  padding: 10px 20px;

}

.custom-btn:hover {
  background-color: var(--custom-btn-bg-hover-color);
  color: var(--white-color);
}

.custom-btn2 {
  background-color: var(--custom-btn-bg-color);
  border: 2px solid transparent;
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-family: var(--title-font-family);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-semibold);
  line-height: normal;
  transition: all 0.3s;
  padding: 0;
}

.custom-border-btn2 {
  background: transparent;
  border-color: var(--white-color);
  color: var(--white-color);
}

/* .custom-btn2:hover {
  background: var(--white-color);
  color: var(--secondary-color);
} */
.custom-border-btn {
  background: transparent;
  border: 2px solid var(--custom-btn-bg-color);
  color: var(--custom-btn-bg-color);
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 13px;
  padding-left: 13px;
}

.custom-border-btn:hover {
  background: var(--custom-btn-bg-color);
  border-color: transparent;
  color: var(--primary-color);
}

.custom-btn-bg-white {
  border-color: var(--white-color);
  color: var(--white-color);
}

/*---------------------------------------
  SITE HEADER              
-----------------------------------------*/
.site-header {
  background-image: url('./assets/images/templatemo-wave-header.jpg'), linear-gradient(#348CD2, #FFFFFF);

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 480px;

  position: relative;
}

.site-header h2 {
  color: var(--white-color);
}

/*---------------------------------------
  NAVIGATION              
-----------------------------------------*/
.navbar {
  background-color: #fefefe;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar .navbar-brand,
.navbar .navbar-brand:hover {
  color: var(--white-color);
}

.logo-image {
  width: 100%;
  height: auto;
}

.navbar-brand,
.navbar-brand:hover {
  font-size: var(--h3-font-size);
  font-weight: var(--font-weight-bold);
  display: inline-block;
}

.navbar-brand span {
  font-family: var(--title-font-family);
}

.navbar-nav .nav-link {
  display: inline-block;
  color: #000000;
  font-family: var(--title-font-family);
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}



.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover {
  color: var(--secondary-color);
}

.navbar .dropdown-menu {
  background: var(--white-color);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  border: 0;
  display: inherit;
  opacity: 0;
  min-width: 15rem;
  margin-top: 20px;
  padding: 13px 0 10px 0;
  transition: all 0.3s;
  pointer-events: none;
}

.mobile-dropdown-menu {
  background: var(--white-color);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  border: 0;
  display: inherit;
  opacity: unset !important;
  min-width: 12rem;
  margin-top: 20px;
  padding: 13px 0 10px 0;
  transition: all 0.3s;
  pointer-events: painted !important;
}

.navbar .dropdown-menu::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 15px solid var(--white-color);
  position: absolute;
  top: -10px;
  right: 10px;
}

.navbar .dropdown-item {
  display: inline-block;
  color: var(--p-bg-color);
  font-family: var(--title-font-family);
  font-size: 15px;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  position: relative;
}



.navbar .dropdown-toggle::after {
  content: "\f282";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-size: var(--menu-font-size);
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  left: 2px;
  border: 0;
}

@media screen and (min-width: 992px) {
  .navbar .dropdown:hover .dropdown-menu {
    opacity: 1;
    margin-top: 0;
    pointer-events: auto;
    right: 0;
  }
}

.navbar .custom-border-btn {
  border-color: var(--white-color);
  color: var(--white-color);
}

.navbar .custom-border-btn:hover {
  background: var(--white-color);
  color: var(--secondary-color);
}

.navbar-toggler {
  border: 0;
  padding: 0;
  cursor: pointer;
  margin: 0;
  width: 30px;
  height: 35px;
  outline: none;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background: transparent;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before,
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transition: top 300ms 50ms ease, -webkit-transform 300ms 350ms ease;
  transition: top 300ms 50ms ease, transform 300ms 350ms ease;
  transition: top 300ms 50ms ease, transform 300ms 350ms ease,
    -webkit-transform 300ms 350ms ease;
  top: 0;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(-45deg);
}

.navbar-toggler .navbar-toggler-icon {
  background: var(--secondary-color);
  transition: background 10ms 300ms ease;
  width: 30px;
  height: 2px;
  position: relative;
}

.navbar-toggler .navbar-toggler-icon:before,
.navbar-toggler .navbar-toggler-icon:after {
  transition: top 300ms 350ms ease, -webkit-transform 300ms 50ms ease;
  transition: top 300ms 350ms ease, transform 300ms 50ms ease;
  transition: top 300ms 350ms ease, transform 300ms 50ms ease,
    -webkit-transform 300ms 50ms ease;
  position: absolute;
  right: 0;
  left: 0;
  background: var(--secondary-color);
  width: 30px;
  height: 2px;
  content: "";
}

.navbar-toggler .navbar-toggler-icon::before {
  top: -8px;
}

.navbar-toggler .navbar-toggler-icon::after {
  top: 8px;
}

/*---------------------------------------
  CAROUSEL        
-----------------------------------------*/
.hero-section {
  background-image: url(assets/images/cellcash/bg1.webp),
    linear-gradient(#348cd2, #ffffff);
  background-repeat: no-repeat;
  background-size: 108% 76%;
  background-position: top;
  padding-top: 200px;
}

.bgImage {
  background-image: url(assets/images/cellcash/bg1.webp),
    linear-gradient(#348cd2, #ffffff);

  background-repeat: no-repeat;

  background-size: 108% 100%;
  background-position: top;
}

.card-padding {
  padding: 12px 0px !important;
}

.hitwIcon {
  padding-top: 14px;
  width: 62px;
}

.owl-carousel {
  text-align: center;
}

.owl-carousel-image {
  display: block;
}

.owl-carousel .owl-item .owl-carousel-verified-image {
  display: inline-block;
  width: 30px;
  height: auto;
  position: relative;
  right: 5px;
}

.verified-image {
  display: inline-block;
  width: 20px;
  height: auto;
}

.owl-carousel .owl-item {
  opacity: 0.35;
}

.owl-carousel .owl-item.active.center {
  opacity: 1;
}

.owl-carousel-info-wrap {
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
  text-align: left;
}

.owl-carousel-info {
  background-color: var(--section-bg-color);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 30px;
}

.badge {
  background-color: var(--custom-btn-bg-color);
  font-family: var(--title-font-family);
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  padding-bottom: 5px;
}

.owl-carousel-info-wrap .social-share,
.team-thumb .social-share {
  position: absolute;
  right: 0;
  bottom: 0;
}

.owl-carousel-info-wrap .social-icon,
.team-thumb .social-icon {
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateX(0);
  padding-right: 20px;
  padding-left: 20px;
}

.owl-carousel .owl-item.active.center .owl-carousel-info-wrap:hover .social-icon,
.team-thumb:hover .social-icon {
  transform: translateY(-100%);
  opacity: 1;
}

.owl-carousel-info-wrap .social-icon-item,
.owl-carousel-info-wrap .social-icon-link,
.team-thumb .social-icon-item,
.team-thumb .social-icon-link {
  display: block;
  margin-bottom: 10px;
  margin-left: auto;
}

.owl-carousel-info-wrap .social-icon-link {
  margin-top: 5px;
  margin-bottom: 5px;
}

.owl-carousel .owl-dots {
  background-color: var(--white-color);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  border-radius: var(--border-radius-large);
  display: inline-block;
  margin: auto;
  margin-top: 40px;
  padding: 15px 25px;
  padding-bottom: 7px;
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 40px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: var(--secondary-color);
}

/*---------------------------------------
  CUSTOM BLOCK              
-----------------------------------------*/

.custom-block-info {
  display: block;
  padding: 10px 20px;
  padding-bottom: 0;
}

.custom-block-image-wrap {
  position: relative;
  display: block;
  height: 100%;
}

.custom-block-image-wrap>a {
  display: block;
}

.custom-block-image {
  border-radius: var(--border-radius-medium);
  display: block;
  width: 112px;
  height: 112px;
  object-fit: cover;
}

.custom-block-image-detail-page .custom-block-image {
  width: 100%;
  height: 212px;
}

.custom-block .custom-block-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-block-icon-wrap {
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
}

.custom-block-icon-wrap .section-overlay {
  opacity: 0.25;
}

.custom-block-btn-group {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 20px;
}

.custom-block-btn-group .custom-block-icon {
  position: relative;
  top: 0;
  left: 0;
  transform: none;
}

.custom-block-icon {
  background: var(--primary-color);
  border-radius: var(--border-radius-medium);
  font-size: var(--p-font-size);
  color: var(--white-color);
  text-align: center;
  width: 32.5px;
  height: 32.5px;
  line-height: 32.5px;
  transition: all 0.3s;
}

.custom-block-icon:hover {
  background: var(--secondary-color);
  color: var(--white-color);
}

.custom-block .custom-btn {
  font-size: var(--menu-font-size);
  padding: 7px 15px;
}

.custom-block .custom-block-info+div .badge {
  background-color: var(--dark-color);
  color: var(--white-color);
  border-radius: 50px !important;
  font-size: var(--menu-font-size);
  display: flex;
  justify-content: center;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.custom-block .custom-block-info+div .badge:hover {
  background-color: var(--secondary-color);
}

.custom-block-full {
  background-color: var(--section-bg-color);
  border-color: transparent;
  padding: 12px !important;
  min-height: 170px;
}

.custom-block-full:hover {
  border-color: var(--primary-color);
}

.custom-block-full .custom-block-info {
  padding: 10px !important;
  padding-bottom: 0;
}

.custom-block-full .social-share {
  position: absolute;
  top: 0;
  right: 0;
  margin: 50px;
}

.custom-block-top small {
  color: var(--p-color);
  font-family: var(--title-font-family);
}

.custom-block-top .badge {
  background-color: var(--secondary-color);
  color: var(--white-color);
  display: inline-block;
  vertical-align: middle;
  height: 26.64px;
  line-height: 20px;
}

.custom-block-bottom a:hover span {
  color: var(--primary-color);
}

.custom-block-bottom a span {
  font-family: var(--title-font-family);
  color: var(--p-color);
  text-transform: uppercase;
  margin-left: 3px;
}

.custom-block-overlay {
  border-color: transparent;
  padding: 0;
}

.custom-block-overlay .custom-block-image {
  margin: auto;
  width: 100%;
  height: 210px;
  transition: all 0.3s;
}

.custom-block-overlay:hover .custom-block-image {
  padding: 15px;
  padding-bottom: 0;
}

.custom-block-overlay-info {
  padding: 15px 20px 20px 20px;
}

/*---------------------------------------
  PROIFLE BLOCK               
-----------------------------------------*/
.profile-block {
  margin-top: 10px;
}

.profile-block-image {
  border-radius: var(--border-radius-large);
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.profile-block p strong {
  display: block;
  font-family: var(--title-font-family);
}

.profile-detail-block {
  border: 1px solid #dee2e6;
  border-radius: var(--border-radius-large);
  padding: 12px 25px;
}

.profile-detail-block p {
  margin-bottom: 0;
}

/*---------------------------------------
  ABOUT & TEAM SECTION               
-----------------------------------------*/
.about-image {
  border-radius: var(--border-radius-medium);
  display: block;
}

.team-thumb {
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
  height: 146px;
  text-align: center;
}

.team-info {
  background-color: var(--white-color);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 30px;
}

/*---------------------------------------
  PAGINATION               
-----------------------------------------*/
.pagination {
  border: 1px solid #dee2e6;
  border-radius: var(--border-radius-large);
  padding: 20px;
}

.page-link {
  border: 0;
  border-radius: var(--border-radius-small);
  color: var(--p-color);
  font-family: var(--title-font-family);
  margin: 0 5px;
  padding: 10px 20px;
}

.page-link:hover,
.page-item:first-child .page-link:hover,
.page-item:last-child .page-link:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.page-item:first-child .page-link {
  margin-right: 10px;
}

.active>.page-link,
.page-link.active {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

/*---------------------------------------
  CONTACT               
-----------------------------------------*/
.contact-info p strong {
  font-family: var(--title-font-family);
  min-width: 90px;
}

.contact-info p a {
  color: var(--p-color);
  border-bottom: 1px solid;
  padding-bottom: 3px;
}

.contact-info p a:hover {
  color: var(--secondary-color);
}

.google-map {
  border-radius: var(--border-radius-medium);
}

.contact-form .form-floating>textarea {
  border-radius: var(--border-radius-medium);
  height: 150px;
}

/*---------------------------------------
  SUBSCRIBE FORM               
-----------------------------------------*/
.subscribe-form-wrap {
  border: 1px solid var(--white-color);
  border-radius: var(--border-radius-small);
  width: 80%;
  position: relative;
  top: 12px;
  padding: 35px;
}

.subscribe-form-wrap h6 {
  background: var(--white-color);
  border-radius: var(--border-radius-medium);
  color: var(--primary-color);
  text-align: center;
  position: relative;
  bottom: 55px;
  margin-bottom: -25px;
  padding: 8px;
}

.subscribe-form #subscribe-email {
  border: 0;
  border-radius: 10px 10px 0 0;
  margin-bottom: 0;
}

.subscribe-form #submit {
  border-radius: 0 0 10px 10px;
}

/*---------------------------------------
  CUSTOM FORM               
-----------------------------------------*/
.custom-form .form-control {
  border-radius: var(--border-radius-small);
  color: var(--p-color);
  font-family: var(--title-font-family);
  font-size: var(--p-font-size);
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  outline: none;
  border: 1px solid #7e57c2 !important;
}

.form-floating>label {
  padding-left: 20px;
}

.custom-form button[type="submit"] {
  background: var(--custom-btn-bg-color);
  border: none;
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-family: var(--title-font-family);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-semibold);
  transition: all 0.3s;
  margin-bottom: 0;
}

.custom-form button[type="submit"]:hover,
.custom-form button[type="submit"]:focus {
  background: #000;
  border-color: #000 !important;
}

/*---------------------------------------
  SEARCH FORM               
-----------------------------------------*/
.search-form .form-control {
  border: 0;
  margin-bottom: 0;
}

.search-form button[type="submit"] {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
  max-width: 50px;
  height: 100%;
  padding-left: 15px;
}

.search-form button[type="submit"]:hover {
  background: var(--primary-color);
}

/*---------------------------------------
  SITE FOOTER              
-----------------------------------------*/
.site-footer {
  background-color: #000;
}

.site-footer>.container {
  position: relative;
  bottom: 20px;
  padding-top: 20px;
}

.site-footer-title,
.site-footer p {
  color: var(--white-color);
}

.site-footer p strong {
  font-family: var(--title-font-family);
}

.site-footer p a {
  color: var(--white-color);
  border-bottom: 1px solid;
  padding-bottom: 3px;
}

.site-footer p a:hover {
  color: var(--secondary-color);
}

.site-footer-thumb a img {
  display: block;
  width: 120px;
  min-width: 120px;
  height: auto;
}

/* .site-footer-links {
  margin-bottom: 0;
  padding-left: 0;
} */


.site-footer-link-item {
  display: block;
  list-style: none;
  margin-right: 10px;
  margin-left: 10px;
  text-align: left;
}

.site-footer-link {
  color: var(--white-color);

  line-height: inherit;
}

.p30 {
  padding: 30px !important;
}

.textcenter {
  text-align: center;
}

/*---------------------------------------
  SOCIAL ICON               
-----------------------------------------*/
.social-icon {
  margin: 0;
  padding: 0;
}

.social-icon-item {
  list-style: none;
  display: inline-block;
  vertical-align: top;
}

.social-icon-link {
  background: var(--secondary-color);
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-size: var(--p-font-size);
  display: block;
  margin-right: 10px;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  transition: background 0.2s, color 0.2s;
}

.social-icon-link:hover {
  background: var(--primary-color);
  color: var(--white-color);
}

/*---------------------------------------
  RESPONSIVE STYLES               
-----------------------------------------*/
@media screen and (min-width: 2160px) {
  .hero-section {
    background-size: 100% 90%;
    /* T o o p l a t e . c o m   C u s t o m i z e d */
  }
}

@media screen and (min-width: 1600px) {
  .site-footer {
    padding-top: 0px;
  }
}

@media screen and (max-width: 1240px) {
  .hero-section {
    background-size: 116%;
    /* T o o p l a t e . c o m   C u s t o m i z e d */
  }
}

@media screen and (max-width: 991px) {




  .hero-section {
    background-size: 160% 66%;
    /* T o o p l a t e . c o m   C u s t o m i z e d */
  }

  .section-padding {
    padding-top: 10px;
    padding-bottom: 50px;
  }

  .navbar-nav {
    background-color: var(--primary-color);
    border-radius: var(--border-radius-medium);
    padding: 30px;
  }

  .navbar-nav .nav-link {
    padding: 5px 0;
  }

  .navbar-nav .dropdown-menu {
    position: relative;
    left: 10px;
    opacity: 1;
    pointer-events: auto;
    max-width: 155px;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .navbar-expand-lg .navbar-nav {
    padding-bottom: 20px;
  }

  .nav-tabs .nav-link:first-child {
    margin-right: 5px;
  }

  .nav-tabs .nav-link {
    font-size: var(--copyright-font-size);
    padding: 10px;
  }

  .copyright-text {
    text-align: center;
  }

  .site-footer {

    padding-top: 20px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 540px) {
  .section-padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .hero-section {
    background-size: 180% 65%;
    /* T o o p l a t e . c o m   C u s t o m i z e d */
  }

  .custom-block .custom-block-top {
    flex-direction: column;
  }

  .custom-block .custom-block-top small:last-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 480px) {

  .mobile-dropdown-menu .dropdown-item.active,
  .dropdown-item:active {
    color: #000;
    text-decoration: none;
    background-color: transparent !important;
  }



  .option-itemName {
    width: 10.9rem !important;
  }
}

@media screen and (max-width: 414px) {
  .section-padding {
    padding-top: 10px;
    padding-bottom: 50px;
  }

  .search-form {
    max-width: 100%;
  }
}

.p0 {
  padding: 0px !important;
}

.custom-border-btn {
  border-color: var(--white-color);
  color: var(--white-color);
}

.custom-border-btn {
  background: transparent;
  border: 2px solid var(--custom-btn-bg-color);
  color: var(--custom-btn-bg-color);
}

.custom-btn {
  background-color: var(--custom-btn-bg-color) !important;
  border: 2px solid transparent;
  border-radius: var(--border-radius-large);
  color: var(--white-color) !important;
  font-family: var(--title-font-family);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-semibold);
  line-height: normal;
  transition: all 0.3s;
  padding: 10px 20px;
}

.top-header {
  background-color: #7e57c2;
  color: #fff;
}

.top-header .contact-info,
.top-header .social-icons {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 4px;
  padding-bottom: 4px;
}

.top-header .contact-info li,
.top-header .social-icons li {
  display: inline-block;
  margin-right: 20px;
}

.top-header .contact-info li:last-child,
.top-header .social-icons li:last-child {
  margin-right: 0;
}

.top-header .social-icons li a {
  color: #fff;
  text-decoration: none;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center;
}

.float {
  position: fixed;
  width: 46px;
  height: 46px;
  bottom: 40px;
  left: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 26px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: unset;
  position: relative;
  top: 4px;
}

li {
  list-style-type: none;
}

ol,
ul {
  padding-left: 0rem;
}

.bgsellSearch {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--section-bg-color);
  border-color: transparent;
}

.lognPopup button {
  border-radius: 20px;
  border: 1px solid #7e57c2;
  background-color: #7e57c2;

  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.lognPopup button:active {
  transform: scale(0.95);
}

.lognPopup button:focus {
  outline: none;
}

.lognPopup button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

.lognPopup form {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  height: 100%;
  text-align: center;
}

.lognPopup input {
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  margin-bottom: 16px;

  width: 100%;
}

.lognPopup .container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;

  max-width: 100%;
  min-height: 480px;
}

.lognPopup .form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.lognPopup .sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.lognPopup .container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.lognPopup .sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.lognPopup .container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {

  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.lognPopup .overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.lognPopup .container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.lognPopup .overlay {
  background: #7e57c2;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.lognPopup .container.right-panel-active .overlay {
  transform: translateX(50%);
}

.lognPopup .overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.lognPopup .overlay-left {
  transform: translateX(-20%);
}

.lognPopup .container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.lognPopup .overlay-right {
  right: 0;
  transform: translateX(0);
}

.lognPopup .container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.lognPopup .social-container {
  margin: 20px 0;
}

.lognPopup .social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

.lognPopup .modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
  position: relative;
  z-index: 999;
}

.lognPopup {
  padding: 0px !important;
}

/* 

.navbar .logo-image {
  width: 100px;
} */

@media (min-width: 320px) {
  .m-center {
    justify-content: center;
  }

  .m-ScreenImg-icon {
    max-width: 80%;
    height: auto;
    width: 44%;

  }

  .card1 {
    padding: 0;
  }

  .mobile-dropdown-menu .dropdown-item.active,
  .dropdown-item:active {
    color: #000;
    text-decoration: none;
    background-color: transparent !important;
  }

  /* smartphones, iPhone, portrait 480x320 phones */
  .navbar .logo-image {
    width: 130px;
  }

  .slider-Mobile {
    padding-top: 0%;
  }

  .top-header-Desktop {
    display: none;
  }

  .search-Desktop {
    display: none;
  }

  .custom-block-image {
    object-fit: fill !important;
  }

  .five {
    order: 5;
  }

  .four {
    order: 4;
  }

  .three {
    order: 3;
  }

  .two {
    order: 2;
  }

  .one {
    order: 1;
  }

  .social-icon {
    margin: 0;
    padding: 0;
  }

  .services-Desktop {
    display: none;
  }

  .slider-Desktop {
    display: none;
  }

  .custom-block-full .custom-block-image {
    height: 150px;
    width: 100%;
  }

  .custom-block-full {
    background-color: var(--section-bg-color);
    border-color: transparent;
    padding: 0px !important;
    min-height: 150px;
  }

  .site-footer-links {
    margin-bottom: 40px;
    padding-left: 0;
    text-align: left;
  }

  .login-Desktop {
    display: none;
  }

  .sell-result-Desktop {
    display: none;
  }

  .mobile-custom-w {
    width: 33%;
    margin: 20px;
  }

  .mobile-custom-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }

  .navbar {
    position: relative;
    top: 8%;
  }

  .site-footer {

    padding-top: 100px;
    padding-bottom: 0;
  }

  .custom-block {
    padding: 10px;
    border: none;
  }

  .custom-block:hover {
    background: transparent;
    box-shadow: none;
    border-color: transparent;
    transform: none
  }
}

@media (min-width: 481px) {
  .m-center {
    justify-content: center;
  }

  .m-ScreenImg-icon {
    max-width: 80%;
    height: auto;
    width: 44%;

  }

  .card1 {
    padding: 0;
  }

  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .slider-Mobile {
    padding-top: 0%;
  }

  .top-header-Desktop {
    display: none;
  }

  .search-Desktop {
    display: none;
  }

  .custom-block-image {
    object-fit: fill !important;
  }

  .five {
    order: 5;
  }

  .four {
    order: 4;
  }

  .three {
    order: 3;
  }

  .two {
    order: 2;
  }

  .one {
    order: 1;
  }

  .social-icon {
    margin: 0;
    padding: 0;
  }

  .services-Desktop {
    display: none;
  }

  .slider-Desktop {
    display: none;
  }

  .custom-block-full .custom-block-image {
    height: 150px;
    width: 100%;
  }

  .site-footer-links {
    margin-bottom: 40px;
    padding-left: 0;
    text-align: left;
  }

  .login-Desktop {
    display: none;
  }

  .sell-result-Desktop {
    display: none;
  }

  .navbar {
    position: relative;

  }

  .site-footer {

    padding-top: 100px;
    padding-bottom: 0;
  }
}

@media (min-width: 641px) {
  .m-center {
    justify-content: center;
  }

  .m-ScreenImg-icon {
    max-width: 80%;
    height: auto;
    width: 100px;
  }

  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width: 961px) {

  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .navbar .logo-image {
    width: 200px;
  }

  .top-header-Mobile {
    display: none;
  }

  .top-header-Desktop {
    display: block;
  }

  .search-Mobile {
    display: none;
  }

  .search-Desktop {
    display: block;
  }

  .five {
    order: unset;
  }

  .four {
    order: unset;
  }

  .three {
    order: unset;
  }

  .two {
    order: unset;
  }

  .one {
    order: unset;
  }

  .services-Mobile {
    display: none;
  }

  .slider-Mobile {
    display: none;
  }

  .site-footer-links {
    margin-bottom: 0px;
    padding-left: 0;
    text-align: right;
  }

  .login-Mobile {
    display: none;
  }

  .login-Desktop {
    display: block;
  }

  .sell-result-Mobile {
    display: none;
  }

  .sell-result-Desktop {
    display: block;
  }

  .navbar {
    position: relative;
    top: 0%;
  }

  .site-footer {
    margin-top: -0px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (min-width: 1025px) {
  .cancelorderWith {
    width: 40%;
  }

  .custom-block {
    border: 2px solid var(--primary-color);
    border-radius: var(--border-radius-medium);
    position: relative;
    overflow: hidden;
    padding: 30px;
    transition: all 0.3s ease;
  }

  .custom-block:hover {
    background: var(--white-color);
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    border-color: transparent;
    transform: translateY(-3px);
  }

  .card1 {
    padding: 1.5rem;
  }

  .navbar .dropdown-item.active,
  .navbar .dropdown-item:active,
  .navbar .dropdown-item:focus,
  .navbar .dropdown-item:hover {
    background-color: transparent;
    color: var(--secondary-color);
  }

  /* big landscape tablets, laptops, and desktops */
  .navbar .logo-image {
    width: 200px;
  }

  .top-header-Mobile {
    display: none;
  }

  .search-Mobile {
    display: none;
  }

  .top-header-Desktop {
    display: block;
  }

  .search-Desktop {
    display: block;
  }

  .five {
    order: unset;
  }

  .four {
    order: unset;
  }

  .three {
    order: unset;
  }

  .two {
    order: unset;
  }

  .one {
    order: unset;
  }

  .services-Mobile {
    display: none;
  }

  .services-Desktop {
    display: block;
  }

  .slider-Mobile {
    display: none;
  }

  .slider-Desktop {
    display: block;
  }

  .custom-block-full .custom-block-image {
    width: 100%;
    height: 216px;
  }

  .site-footer-links {
    margin-bottom: 0px;
    padding-left: 0;
    text-align: right;
  }

  .login-Mobile {
    display: none;
  }

  .login-Desktop {
    display: block;
  }

  .sell-result-Mobile {
    display: none;
  }

  .sell-result-Desktop {
    display: block;
  }

  .navbar {
    position: relative;
    top: 0%;
  }

  .site-footer {
    margin-top: 0px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (min-width: 1281px) {

  .custom-block {
    border: 2px solid var(--primary-color);
    border-radius: var(--border-radius-medium);
    position: relative;
    overflow: hidden;
    padding: 30px;
    transition: all 0.3s ease;
  }

  .custom-block:hover {
    background: var(--white-color);
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    border-color: transparent;
    transform: translateY(-3px);
  }

  .card1 {
    padding: 1.5rem;
  }

  .navbar .dropdown-item.active,
  .navbar .dropdown-item:active,
  .navbar .dropdown-item:focus,
  .navbar .dropdown-item:hover {
    background-color: transparent;
    color: var(--secondary-color);
  }

  /* hi-res laptops and desktops */
  .navbar .logo-image {
    width: 200px;
  }

  .search-Mobile {
    display: none;
  }

  .top-header-Mobile {
    display: none;
  }

  .top-header-Desktop {
    display: block;
  }

  .search-Desktop {
    display: block;
  }

  .five {
    order: unset;
  }

  .four {
    order: unset;
  }

  .three {
    order: unset;
  }

  .two {
    order: unset;
  }

  .one {
    order: unset;
  }

  .services-Mobile {
    display: none;
  }

  .services-Desktop {
    display: block;
  }

  .slider-Mobile {
    display: none;
  }

  .slider-Desktop {
    display: block;
  }

  .custom-block-full .custom-block-image {
    width: 100%;
    height: 216px;
  }

  .site-footer-links {
    margin-bottom: 0px;
    padding-left: 0;
    text-align: right;
  }

  .login-Mobile {
    display: none;
  }

  .login-Desktop {
    display: block;
  }

  .sell-result-Mobile {
    display: none;
  }

  .sell-result-Desktop {
    display: block;
  }

  .navbar {
    position: relative;
    top: 0%;
  }

  .site-footer {
    margin-top: 0px;
    padding-top: 40px;
    padding-bottom: 0px;
  }
}

.card-header {
  padding: .875rem 1.5rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(24, 28, 33, 0.06);
}



.actionGrid {
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}

.show .nav-item a {
  color: #fff;
}

.search-icon {
  background-color: transparent !important;
  border: none !important;
  color: #7e57c2 !important;
}

.services-scroll {
  display: flex;

  overflow-x: scroll;
  white-space: nowrap;
}

.services-scroll .service-size {
  width: 80%;
  flex: 0 0 auto;
  margin-right: -5px;
  padding: 0 10px;
}

.hiw-custom-block {
  padding: 12px !important;
  min-height: 176px;
}

.wu-custom-block {
  padding: 30px !important;
  min-height: 270px;
}

.navbar-toggler {
  width: 60px;
  text-align: -webkit-center;
}

.navbar-toggler .collapsed {
  display: none;
}

.collapse:not(.show) {
  display: none;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: unset;
}

.radio-buttons {
  width: 100%;
  margin: 0 auto;
}

.custom-radio input {
  display: none;
}

.radio-btn {
  margin: 10px;
  min-width: 100px;
  padding: 0 10px;
  border: 3px solid transparent;
  display: inline-block;
  border-radius: 10px;
  position: relative;
  text-align: center;
  box-shadow: 0 0 20px #c3c3c367;
  cursor: pointer;
}

.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.radio-btn .hobbies-icon h3 {
  color: #555;
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.custom-radio input:checked+.radio-btn {
  border: 2px solid #7e57c2;
}

.custom-radio input:checked+.radio-btn>i {
  opacity: 1;
  transform: translateX(-50%) scale(1);
}

.hobbies-icon {
  padding-top: 10px;
}

.contactDetail {
  color: #fff;
}

.contactDetail:hover {
  color: #e5e4e4;
}



.error-message {
  color: rgb(255, 0, 0);
  display: block;
  font-size: 12px;
  line-height: 16px;
  margin: 8px 0px;
  text-transform: capitalize;
}

.button-like-link {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  color: var(--custom-btn-bg-color);
  outline: none;
  text-decoration: none;
  font-weight: 500;
}

.form-btn {
  background: var(--custom-btn-bg-color);
  border: none;
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-family: var(--title-font-family);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-semibold);
  transition: all 0.3s;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-btn[type="submit"]:hover,
.form-btn[type="submit"]:focus {
  background: #000;
  border-color: #000 !important;
}

.status-icon {
  width: 24px;
}

.top-search-btn {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
  max-width: 50px;
  height: 100%;
  padding-left: 0px;
}

.custom-form .top-search-btn {
  background: var(--custom-btn-bg-color);
  border: none;
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-family: var(--title-font-family);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-semibold);
  transition: all 0.3s;
  margin-bottom: 0;
  padding-left: 15px;
}

.custom-form .top-search-btn:hover,
.custom-form .top-search-btn:focus {
  background-color: #000;
  border-color: #000 !important;
}

.user-icon-dropdown {
  position: relative;
  display: inline-block;
}

.user-icon {
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.user-icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 50px;
  /* Adjust this value based on your header height */
  background: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 999;
  min-width: 160px;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}

.float-right {
  float: right;
}

.MfontSize {
  font-size: 14px;
}

/* Mobile Icon Styling */
.nav-link {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;
}

.nav-link i {
  font-size: 24px;
  margin-bottom: 5px;
  color: #333;
  transition: color 0.3s ease-in-out;
}

.nav-link:hover {
  color: #7e57c2
}

.nav-link:hover i {
  color: #7e57c2;
}




.custom-block2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;

}

.custom-block-image-wrap img {
  max-width: 100%;
  height: auto;
}

.custom-block2:hover {
  background: var(--white-color);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  border-color: transparent;
  transform: translateY(-3px);
  border-color: var(--primary-color);


}

.custom-block-full:hover {
  border-color: var(--primary-color);
}

/* .section-title {
  text-align: center;
  margin-bottom: 1.5rem;
} */
.custom-block2:focus {
  outline: 2px solid var(--primary-color);
  /* Example focus style */
}

.cardbgChange:hover {
  background-color: #f0f8ffb8;
  color: #000;
}

.cardbgChange {
  width: 100%;
}

/* CTA Section Styling */
.cta-section {
  background-color: #f0f8ff;
  padding: 40px;
  text-align: center;
  border-radius: 10px;
  margin: 40px 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.cta-section h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.cta-section p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 30px;
  max-width: 600px;
  margin: 0 auto;
}

.btn-cta {
  display: inline-block;
  padding: 15px 30px;
  background-color: #7e57c2;
  color: #fff;
  font-size: 1.1rem;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  border-radius: var(--border-radius-large);
}

.btn-cta:hover {
  background-color: #000;
  color: #fff;
}

.deletelink {
  color: rgb(254 100 97) !important;
}