.symIcon {
  width: 35px;
}

.dflex {
  display: flex;
  justify-content: space-between;
}

.iconBoxSum {
  border: 1px solid #cccc;
  padding: 10px;
}

.totalAmountSumry {
  border-top: 1px solid #cccc;
  margin-top: 10px;
  padding-top: 6px;
}

.text_content h2 {
  color: hsl(223, 47%, 23%);
  padding: 30px 0 15px 0;
  font-size: 26px;
  font-weight: 900;
}

.text_content p {
  line-height: 1.5;
  color: rgba(153, 153, 153, 0.979);
  font-size: 15px;
}

.plan {
  /* background-color: hsl(225, 100%, 98%); */
  border-radius: 15px;
  padding: 0 10px;
  justify-content: space-between;
}

.orderPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plan h6 {
  font-size: larger;
  font-weight: 900;
  color: hsl(223, 47%, 23%);
}

.PTP {
  display: block;
  background-color: hsl(261.87deg 46.72% 55.1%);
  font-size: 14px;
  padding: 14px;
  margin: 0;
  margin-top: 20px;
  border-radius: 15px;
  text-decoration: none;
  color: white;
  font-size: medium;
  font-weight: 500;
  box-shadow: 8px 15px 20px hsla(245, 75%, 52%, 0.329);
  text-align: center;
  margin-bottom: 0;
}

.CO {
  text-decoration: none;
  color: rgba(153, 153, 153, 0.979);
  font-size: 13px;
  font-weight: 800;
}

.payment-options {
  margin-bottom: 0px;
}

@media only screen and (max-width: 425px) {
  /* * {
        max-width: 100vw;
        max-height: 100vh;
    } */

  .text_content h2 {
    font-size: 23px;
  }

  .text_content p {
    font-size: 13px;
  }

  .plan {
    padding: 12px;
  }
}

/* Accordion.css */

/* Accordion container */
.accordion {
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

/* Accordion header */
.accordion-header {
  background-color: var(--section-bg-color);
  padding: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Arrow icon */
.arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: #000 transparent transparent transparent;
}

.open {
  transform: rotate(180deg);
}

/* Accordion content */
.accordion-content {
  padding: 10px;
}

.fontSize14 {
  font-size: 14px !important;
}

.pointer {
  cursor: pointer;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #7e57c2;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.edit {
  color: #7e57c2;
}

.delete {
  color: red;
}

.delete:hover {
  color: red;
}

.custom-btn1 {
  background-color: var(--custom-btn-bg-color) !important;
  border: 2px solid transparent;
  border-radius: var(--border-radius-large);
  color: var(--white-color) !important;
  font-family: var(--title-font-family);
  font-size: var(--p-font-size) !important;
  font-weight: var(--font-weight-semibold);
  line-height: normal;
  transition: all 0.3s;
  padding: 10px 20px;
}

.deletelink {
  color: rgb(254 100 97) !important;
}

.hobbies-icon p {
  font-size: 14px !important;
}
.radio-btn {
  padding: 0;
  border: 1px solid #ddd;
}
.hobbies-icon {
  padding-top: 10px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
