.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  background-color: #000000a1;
  width: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #7e57c2;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  margin-top: 10px;
  font-size: 16px;
  color: #fff;
}