/* Modal container */
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.modal.is-active {
    display: flex;
}

/* Modal card */
.modal-card {
    background: #fff;
    border-radius: 6px;
    max-width: 600px;
    width: 100%;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Modal header */
.modal-card-head {
    background-color: #fff;
    padding: 15px 20px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
}

.modal-card-title {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    line-height: normal;
}

.delete {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #aaa;
    transition: color 0.2s ease;
}

.delete:hover {
    color: #333;
}

/* Modal body */
.modal-card-body {
    padding: 20px;
    overflow-y: auto;
    flex-grow: 1;
}

/* Modal footer */
.modal-card-foot {
    padding: 15px 20px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: flex-end;
}

.modal-card-foot .button {
    margin-left: 10px;
}

.button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.button.is-success {
    background-color: #48c774;
    color: #fff;
}

.button.is-success:hover {
    background-color: #36b564;
}

.button.is-danger {
    background-color: #f14668;
    color: #fff;
}

.button.is-danger:hover {
    background-color: #e03e5f;
}