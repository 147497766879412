.phoneBoxText {
  background-color: var(--section-bg-color);
  padding: 20px;
  margin-top: 20px;
}

.custom-block .custom-btn {
  font-size: var(--menu-font-size);
  padding: 12px 15px !important;
  font-size: 16px !important;
}

.custom-block1 {
  border: none;
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
  padding: 30px;
  transition: all 0.3s ease;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
}